import moment from "moment";

let filters = {
    dateTimeNormal(val) {
        if (!val) {
            return;
        }
        return moment(val).format('DD.MM.YYYY HH:mm');
    },
    dateTimeAbbstract(val){
        if (!val) {
            return;
        }
        let today = moment();
        let yesterday = moment().add(-1, "day");
        let date = moment(val);
        if(today.startOf('day') < date && today.endOf('day') > date){
            return `Сегодня в ${date.format('HH:mm')}`;
        }else if(yesterday.startOf('day') < date && yesterday.endOf('day') > date){
            return `Вчера в ${date.format('HH:mm')}`;
        }else{
            return date.format('DD.MM.YYYY HH:mm');
        }
    }
};

export default filters;