<template>
  <div id="appeals">
    <v-container>
      <v-row align="center">
        <v-col class="d-flex" cols="12" sm="6">
          <v-text-field type="date" v-model="filter.date"></v-text-field>
        </v-col>
      </v-row>
      <v-expansion-panels>
        <v-expansion-panel v-for="appeal in appeals" :key="appeal.id">
          <v-expansion-panel-header :style="getAppealstyle(appeal)">
            <div>
              <span class="badge-count" v-if="appeal.count > 1">{{
                appeal.count
              }}</span>
              <span class="appeal-date">{{
                appeal.time | dateTimeAbbstract
              }}</span>
              <span class="appeal-status">
                {{ statusAppeal(appeal.status) }}
              </span>
              <span>
                {{ appeal.client ? appeal.client.name : appeal.client_number }}
              </span>
              <template v-if="appeal.direction == 1">
                <i class="fa fa-arrow-right"></i>
              </template>
            </div>
          </v-expansion-panel-header>
          <v-expansion-panel-content>
            <v-simple-table dense>
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>Данные абонента:</td>
                    <td>
                      <v-btn icon color="warning" @click="editClient(appeal)">
                        <v-icon>mdi-pencil-outline</v-icon>
                      </v-btn>
                    </td>
                  </tr>
                  <tr>
                    <td>Телефон:</td>
                    <td>
                      <a :href="`tel:+${appeal.client_number}`">{{
                        appeal.client_number
                      }}</a>
                    </td>
                  </tr>
                  <tr v-if="appeal.client">
                    <td>Имя:</td>
                    <td>{{ appeal.client.name }}</td>
                  </tr>
                  <tr>
                    <td>Статус:</td>
                    <td>{{ statusAppeal(appeal.status) }}</td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <v-simple-table
              dense
              v-for="(item, key) in appeal.appeals"
              :key="'item' + key"
            >
              <template v-slot:default>
                <tbody>
                  <tr>
                    <td>
                      <v-icon :color="getAppealColor(item)">{{
                        getAppealIcon(item)
                      }}</v-icon>
                      {{ item.start_time | dateTimeAbbstract }}
                    </td>
                  </tr>
                  <tr v-if="item.recording">
                    <td>
                      <audio :src="item.recording" controls></audio>
                    </td>
                  </tr>
                </tbody>
              </template>
            </v-simple-table>
            <!-- <v-card>
              
            </v-card> -->
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
    </v-container>
    <v-row justify="center">
      <v-dialog v-model="dialog" scrollable max-width="300px">
        <v-card>
          <v-card-title>Данные абонента:</v-card-title>
          <v-divider></v-divider>
          <v-card-text>
            <v-form ref="form">
              <v-text-field
                v-model="client.phone"
                label="Телефон"
                disabled
              ></v-text-field>

              <v-text-field v-model="client.name" label="Имя"></v-text-field>

              <v-select
                v-model="client.status"
                :items="statuses"
                item-text="name"
                item-value="id"
                label="Статус"
              ></v-select>
            </v-form>
          </v-card-text>
          <v-divider></v-divider>
          <v-card-actions>
            <v-btn color="blue darken-1" text @click="dialog = false">
              Закрыть
            </v-btn>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="saveClient()">
              Сохранить
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-row>
  </div>
</template>

<script>
import moment from "moment";
import { mapState } from "vuex";
import api from "../api";
import filters from "../filters";

export default {
  name: "Home",
  data() {
    return {
      client: {
        id: null,
        appeal_id: null,
        phone: null,
        name: null,
        status: null,
      },
      appeals: [],
      statuses: [],
      dates: [],
      filter: {
        date: moment().format("YYYY-MM-DD"),
      },
      dialog: false,
    };
  },
  computed: {
    ...mapState({
      account_id: (state) => state.account_id,
      states: (state) => state.states,
    }),
    state() {
      let state = null;
      for (let i in this.states) {
        let item = this.states[i];
        if (item.name == "appeals") {
          state = item.state;
        }
      }
      return state;
    },
    duration() {
      let duration = 0;
      for (let i in this.appeals) {
        let appeal = this.appeals[i];
        duration += Number(appeal.duration);
      }
      return parseInt(duration / 60);
    },
    countCalls() {
      let count = 0;
      for (let i in this.appeals) {
        let appeal = this.appeals[i];
        count += Number(appeal.count);
      }
      return count;
    },
  },
  watch: {
    filter: {
      deep: true,
      handler() {
        this.getAppeals();
      },
    },
    account_id() {
      this.getAppeals();
    },
    duration() {
      this.$store.dispatch("set", { duration: this.duration });
    },
    countCalls() {
      this.$store.dispatch("set", { countCalls: this.countCalls });
    },
    state(){
      this.getAppeals();
    }
  },
  created() {
    this.initEvents();
    this.getData();
    this.getAppeals();
  },
  methods: {
    initEvents() {
      let _self = this;
      document.addEventListener("states", (e) => {
        _self.getAppeals(false);
      });
    },
    api(data, resolve = false, loader = true) {
      api(this, data, location.pathname, resolve, loader);
    },
    getData() {
      let data = new FormData();
      data.set("type", "getData");
      this.api(data);
    },
    getAppeals(loader = true) {
      this.filter.account_id = this.account_id;
      let data = new FormData();
      data.set("type", "getAppeals");
      data.set("filter", JSON.stringify(this.filter));
      this.api(data, false, loader);
    },
    statusAppeal: function (val) {
      let status;
      for (let i in this.statuses) {
        if (val == this.statuses[i].id) {
          status = this.statuses[i].name;
        }
      }
      return status;
    },
    getAppealstyle: function (appeal) {
      let style = {
        backgroundColor: "rgba(255, 255, 255, 1)",
      };
      for (let i in this.statuses) {
        if (appeal.status == this.statuses[i].id && this.statuses[i].color) {
          style.backgroundColor = this.statuses[i].color;
        }
      }
      style.color = this.invertColor(style.backgroundColor);
      return style;
    },
    invertColor: function (color) {
      color = Array.prototype.join.call(arguments).match(/(-?[0-9\.]+)/g);
      for (var i = 0; i < color.length; i++) {
        color[i] = color[i] >= 127 ? 0 : 255;
      }
      return "rgba(" + color[0] + "," + color[1] + "," + color[2] + ",1)";
    },
    getAppealIcon(appeal) {
      let icon;
      if (appeal.direction == 1) {
        icon = "mdi-call-made";
      } else {
        if (appeal.recording) {
          icon = "mdi-call-received";
        } else {
          icon = "mdi-call-missed";
        }
      }
      return icon;
    },
    getAppealColor(appeal) {
      let color;
      if (appeal.direction == 1) {
        if (appeal.recording) {
          color = "blue";
        } else {
          color = "red";
        }
      } else {
        if (appeal.recording) {
          color = "green";
        } else {
          color = "red";
        }
      }
      return color;
    },
    editClient(appeal) {
      this.client = {
        id: appeal.client ? appeal.client.id : null,
        appeal_id: appeal.id,
        phone: appeal.client_number,
        name: appeal.client ? appeal.client.name : null,
        status: appeal.status,
      };
      this.dialog = true;
    },
    saveClient() {
      let data = new FormData();
      data.set("type", "saveClient");
      data.set("client", JSON.stringify(this.client));
      let _self = this;
      let promise = new Promise((resolve) => {
        _self.api(data, resolve);
      });
      promise.then(() => {
        _self.getAppeals();
        _self.dialog = false;
      });
    },
  },
  filters: filters,
};
</script>

<style lang="less">
#appeals {
  .v-expansion-panel-header {
    padding: 20px 24px 20px 30px !important;
  }
  .v-expansion-panel-content__wrap {
    padding: 24px 16px !important;
  }
  .badge-count {
    display: block;
    position: absolute;
    left: 0;
    bottom: 0;
    font-size: 10px;
    padding: 2px 5px;
    background-color: #37a737;
    color: white;
    border-radius: 0px 5px 0px 0px;
  }
  .appeal-status {
    position: absolute;
    right: 24px;
    bottom: 0;
    display: block;
    padding: 3px;
    font-size: 10px;
  }
  span.appeal-date {
    position: absolute;
    left: 30px;
    bottom: 0;
    font-size: 10px;
    display: block;
    padding: 2px;
  }
  .v-expansion-panel-content__wrap {
    background-color: #ebebeb;
    padding: 5px;
  }
  .v-data-table:not(:last-child) {
    margin-bottom: 20px;
  }
  tr:hover {
    background: #ffffff !important;
  }
}
</style>
